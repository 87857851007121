@import "./landingVariables.css";

.landing {
  display: block;
  width: 75%;
  min-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.landing .paragraph {
  width: 60%;
  min-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.landing p {
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

h2 {
  text-transform: uppercase;
  font-size: 22px;
}

ol li {
  padding: 0 0 40px 0;
}

.section {
  padding: 10px;
}

.grid {
  display: grid;
  grid-column-gap: 30px;
}

.item1 {
  grid-column: 1;
}

.item2 {
  grid-column: 2;
}

.imageLogin {
  display: block;
  max-width: 350px;
}

.image {
  display: block;
  max-width: 450px;
}

.center {
  text-align: center;
}

.tableWrapper {
  overflow-x: scroll;
  padding: 15px 0;
}
.table {
  display: grid;
  grid-template-columns: 250px 200px 150px;
  border-top: var(--border);
  border-left: var(--border);
  width: 600px;
}
.table .col {
  border-right: var(--border);
}
.table p {
  padding: 0 0.5em;
}
.tableFootnote {
  display: grid;
  width: 600px;
  border-top: var(--border);
  border-bottom: var(--border);
  border-left: var(--border);
  border-right: var(--border);
}
.tableFootnote p {
  color: red;
  text-align: center;
  padding: 0 10px;
}
.label {
  font-weight: bold;
}
.label p {
  text-align: center;
}
.btn {
  background-color: #f06d61;
  border-color: #ee574a;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428571429;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  padding: 0 100px;
  text-decoration: none;
}

.btn p {
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  padding: 5px;
}
